import { render, staticRenderFns } from "./WebDownPage.vue?vue&type=template&id=56115ea0&scoped=true&"
import script from "./WebDownPage.vue?vue&type=script&lang=js&"
export * from "./WebDownPage.vue?vue&type=script&lang=js&"
import style0 from "./WebDownPage.vue?vue&type=style&index=0&id=56115ea0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56115ea0",
  null
  
)

export default component.exports