<template>
  <div class="login">
    <BackgroundLogo></BackgroundLogo>
    <div class="container mx-auto">
      <div class="my-3">
        <div class="my-3">
          <img
            class="w-80"
            style="max-width: 300px"
            src="../assets/Maintenancing/image.png"
            alt=""
          />
        </div>
        <h6 style="line-height: 1.6">
          เเจ้งปิดปรับปรุงระบบ VERSCAN ชั่วคราว
          <br />
          <nobr>ตั้งแต่เวลา 19:00 – 00:00 น.</nobr>
          <br />
          <nobr>ในวันที่ 9-10 ส.ค. 66</nobr>
        </h6>
      </div>
    </div>

    <div class="copyright" @click="$router.push({ name: 'Login' })">
      Copyright © 2021 House of Dev Technology Co., Ltd. All rights reserved.
    </div>
  </div>
</template>

<script>
import BackgroundLogo from "@/components/BackgroundLogo.vue";

export default {
  components: { BackgroundLogo },
};
</script>

<style lang="scss" scoped>
.copyright {
  color: #7c7c7c;
  position: fixed;
  bottom: 10px;
  text-align: center;
  width: 100%;
  font-size: 12px;
}
body {
  background: rgba(244, 244, 244);
}
.container {
  display: flex;
  justify-content: center;
}
.link-color {
  color: $color-link;
}
.header-text {
  color: $font-black;
}
.login {
  z-index: 0;
}

.password-toggler {
  position: absolute;
  width: 15px;
  height: auto;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.link-forget {
  display: block;
  padding-top: 10px;
  font-size: 0.75rem;
  color: $color-active-blue;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .card-form {
    background-color: white;
    // height: auto;
    padding: 1.5rem;
    padding-bottom: 2rem;
  }
}
// .card-body {
//   display: flex;
//   justify-content: center;
//   align-content: center;
// }
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 2px solid #7c7c7c;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.input-group {
  background-color: #e0e0e0;
}
@media only screen and (min-width: 1024px) {
  .card-body {
    margin-left: 30%;
    margin-right: 30%;
  }
}

@media only screen and (max-height: 564px) {
  .copyright {
    display: none;
  }
} // @media only screen and (min-width: 1024px) {
//   .card-body{
//     display: flex;
//     justify-content: center;
//   }
// }
</style>
